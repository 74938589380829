import React, { lazy, Suspense } from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Routes,
} from "react-router-dom"

const Home = lazy(() => import("./Home"))
const NewBoard = lazy(() => import("./NewBoard"))
const Board = lazy(() => import("./Board"))

export default function App() {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/new' element={<NewBoard />} />
            <Route exact path='/board/:boardId' element={<Board />} />

            <Route path='*' element={<div>Page not found</div>} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  )
}
